import { CloudUpload, Policy } from "@mui/icons-material";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import Iconify from "../../components/iconify";
const claimIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/file-claim.svg";
const superTopup =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/SuperTopup.png";
const auditDetails =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/AuditDetails.png";
const activeTrace =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/ActiveTrace.png";
const ReEnroll =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/Re-enroll.png";
const whatsapp =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/whatsapp.png";
const UserIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/user.png";
const ConfigIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/config.png";
const InfoIcon =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/icons/info.png";
const download_path =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/download.svg";
const view_path =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/view.svg";
const dependents =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/dependents.svg";
const sendInvite =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/sendinvite.svg";
const email_path =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/emailnew.svg";
const healthId =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/healthid.svg";
const edit_path =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/editnew.svg";
const copy_path =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/copy.svg";
const removeTrace =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/removetrace.svg";
const delete_path =
    "https://imtbepublic.s3.ap-south-1.amazonaws.com/oldAssets/assets/images/util_icons/deletenew.svg";

enum IconType {
    View = "view",
    Edit = "edit",
    HealthId = "healthId",
    Dependents = "dependents",
    Delete = "delete",
    Email = "email",
    SendInvite = "sendInvite",
    Download = "download",
    Policy = "policy",
    Copy = "copy",
    Upload = "upload",
    Whatsapp = "whatsapp",
    RemoveTrace = "removeTrace",
    Info = "info",
    Configuration = "configuration",
    User = "user",
    ReEnroll = "reEnroll",
    Claim = "claim",
    SuperTopup = "superTopup",
    ActiveTrace = "activeTrace",
    AuditDetails = "auditDetails",
    ExtreanlLink = "externalLink",
    Reset = "reset",
}

const commonStyle = { height: "auto", width: "22px" };
const getIcon = (type: IconType) => {
    switch (type) {
        case IconType.View:
            return (
                <div>
                    <img src={view_path} alt="view icon" className="pointer" />
                </div>
            );
        case IconType.Edit:
            return (
                <div>
                    <img
                        src={edit_path}
                        alt="edit_icon"
                        className="iconSize pointer"
                    />{" "}
                </div>
            );
        case IconType.HealthId:
            return (
                <div>
                    <img
                        src={healthId}
                        alt="healthid_icon"
                        className="pointer"
                    />{" "}
                </div>
            );
        case IconType.Dependents:
            return (
                <div>
                    <img
                        src={dependents}
                        alt="dependents_icon"
                        className="pointer"
                    />{" "}
                </div>
            );
        case IconType.Delete:
            return (
                <div>
                    {" "}
                    <img
                        src={delete_path}
                        alt="delete icon"
                        style={{ width: "2.2em", height: "2.2em" }}
                        className="deleteIcon iconSize pointer"
                    />{" "}
                </div>
            );
        case IconType.Email:
            return (
                <div>
                    <img
                        src={email_path}
                        alt="email icon"
                        className="pointer"
                    />{" "}
                </div>
            );
        case IconType.SendInvite:
            return (
                <div>
                    <img
                        src={sendInvite}
                        alt="sendInvite"
                        className="pointer"
                    />{" "}
                </div>
            );
        case IconType.Download:
            return (
                <div>
                    <Iconify
                        icon="material-symbols:download"
                        className="pointer"
                        style={{ fontSize: "24px" }}
                    />
                </div>
            );
        case IconType.ExtreanlLink:
            return (
                <div>
                    <Iconify
                        icon="material-symbols:open-in-new-rounded"
                        className="pointer"
                        style={{ fontSize: "24px" }}
                    />{" "}
                </div>
            );
        case IconType.Policy:
            return (
                <div>
                    <Policy className="pointer" />
                </div>
            );
        case IconType.Copy:
            return (
                <div>
                    <img
                        src={copy_path}
                        alt="Copy Icon"
                        style={commonStyle}
                        className="pointer"
                    />{" "}
                </div>
            );
        case IconType.Upload:
            return (
                <div>
                    <CloudUpload
                        style={{ fill: "#4f3dd1" }}
                        className="pointer"
                    />
                </div>
            );
        case IconType.Whatsapp:
            return (
                <div>
                    <img
                        src={whatsapp}
                        alt="Whatsapp icon"
                        className="pointer"
                    />
                </div>
            );
        case IconType.RemoveTrace:
            return (
                <div>
                    {" "}
                    <img
                        src={removeTrace}
                        alt="removeTrace"
                        style={{ width: "2.2em", height: "2.2em" }}
                        className="removeTrace"
                    />{" "}
                </div>
            );
        case IconType.Info:
            return (
                <div>
                    <img
                        src={InfoIcon}
                        alt="InfoIcon"
                        style={commonStyle}
                        className="pointer"
                    />
                </div>
            );
        case IconType.Configuration:
            return (
                <div>
                    <img
                        src={ConfigIcon}
                        alt="ConfigIcon"
                        style={commonStyle}
                        className="pointer"
                    />
                </div>
            );
        case IconType.User:
            return (
                <div>
                    <img
                        src={UserIcon}
                        alt="UserIcon"
                        style={commonStyle}
                        className="pointer"
                    />
                </div>
            );
        case IconType.ReEnroll:
            return (
                <div>
                    <img
                        src={ReEnroll}
                        style={commonStyle}
                        alt="ReEnrollIcon"
                        className="pointer"
                    />
                </div>
            );
        case IconType.Claim:
            return (
                <div>
                    <img
                        src={claimIcon}
                        style={commonStyle}
                        alt="ClaimIcon"
                        className="pointer"
                    />
                </div>
            );
        case IconType.SuperTopup:
            return (
                <div>
                    <img
                        src={superTopup}
                        alt="SuperTopupIcon"
                        style={commonStyle}
                        className="pointer"
                    />
                </div>
            );
        case IconType.ActiveTrace:
            return (
                <div>
                    <img
                        src={activeTrace}
                        alt="ActiveTraceIcon"
                        style={commonStyle}
                        className="pointer"
                    />
                </div>
            );
        case IconType.AuditDetails:
            return (
                <div>
                    <img
                        src={auditDetails}
                        alt="AuditDetailsIcon"
                        style={commonStyle}
                        className="pointer"
                    />
                </div>
            );
        case IconType.Reset:
            return (
                <div>
                    <div style={{ fontSize: '22px', marginLeft: '4px' }}>&#x21BA;</div>
                </div>
            )
        default:
            return null;
    }
};

export default getIcon;
